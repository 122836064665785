.title {
  margin-bottom: 0 !important;
}

.form-login > .ant-form-item:not(:nth-last-child(2)) {
  margin: 0 0 12px;
}

.error-message {
  margin-bottom: 10px !important;
}

.submit-button {
  font-weight: 600 !important;
}
