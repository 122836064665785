.test {
  background-color: #FFF;
  padding: 2%;
  border-radius: 25px;
}
.form-survey > * {
  margin-top: 30px;
}

.error-message {
  margin-top: 30px;
  width: 100%;
}

.send-btn {
  font-weight: 600;
  text-transform: uppercase;
}